import React from 'react'
import { graphql } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

// Components
import GraphQLErrorList from '../components/graphql-error-list'
import PageHeader from '../components/page-header'
import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query PricingPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    pricing: sanityPricing(_id: { regex: "/(drafts.|)pricingPage/" }) {
      title
      title1
      body
      tiers {
        title
        subtitle
        body
        tierImage {
          asset {
            _id
          }
        }
        price
        list
        cta
      }
    }
  }
`

// Classes
const HeadingClass = 'dib relative w-100 w-60-ns f3 fw5 mt0 mb2'
const ParagraphClass = 'dib relative w-100 measure-wide inherit fw4 mb1'
const ButtonClass = 'dib relative w-100 brand-red tc ph3 pv2 br-pill b link dim pointer'

const PricingPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const pricing = (data || {}).pricing

  if (!pricing) {
    throw new Error(
      'Missing "Pricing" page data. Open the studio at http://localhost:3333 and add "Pricing" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={pricing.title} description={pricing.body} keywords={site.keywords} />
      <article>
        {/* Pricing main */}
        <section className="dib relative w-100 black lh-copy overflow-hidden f5 bg-gradient-2">
          <div className="dib w-100 relative pt6 pt7-l">
            <PageHeader description={pricing.body} heading={pricing.title1} href="#products" />
          </div>
          <div className="db center mw8 ph4 pv6" id="products">
            <div className="row">
              {pricing.tiers.map((object, index) => (
                <div className="col-xs-12 col-md-4 mb4 mb0-l" key={`col-${index}`}>
                  <div
                    className={`dib relative w-100 h-100 br3 pa4 dark-gray bg-white-80 ${
                      index === 1 ? 'bg-white shadow-light' : ''
                    } ${index === 2 ? 'bg-brand-beige' : ''}`}
                  >
                    <img
                      className="db center w4 mb4"
                      src={imageUrlFor(buildImageObj(object.tierImage))}
                      alt={object.title}
                    />
                    <strong className={HeadingClass}>{object.title}</strong>
                    <small className="dib relative w-100 f5 inherit">{object.subtitle}</small>
                    <p className="dib relative w-100 brand-red f5 fw5">{object.price}</p>
                    <p className={`${ParagraphClass}`}>{object.body}</p>
                    <p className="dib relative w-100 f6 fw4 inherit pa0 mb4 pre">{object.list}</p>
                    {index !== 2 ? (
                      <a
                        className={`bg-brand-pink ${ButtonClass}`}
                        target="_blank"
                        href="https://app.lake.finance/"
                      >
                        {object.cta}
                      </a>
                    ) : (
                      <a
                        className={`bg-brand-pink ${ButtonClass}`}
                        href="mailto:h@lake.finance&subject=Lake HNW portfolios"
                      >
                        {object.cta}
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default PricingPage
